import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

var img1 = require('./../../images/banner/bnr1.jpg');
var img2 = require('./../../images/background/bg1.jpg');

const galleryDeatils = [
  {
    image: require('./../../images/gallery/tier2/gallery-thumbnail.png'),
    name: '2 Tier Cakes',
    link: '/gallery/2-tier-cakes'
  },
  {
    image: require('./../../images/gallery/Chocolate/gallery-thumbnail.jpg'),
    name: 'Chocolates',
    link: '/gallery/chocolates'
  },
  {
    image: require('./../../images/gallery/Cupcakes/gallery-thumbnail.jpg'),
    name: 'Cupcakes',
    link: '/gallery/cupcakes'
  },
  {
    image: require('./../../images/gallery/Custom-cakes/gallery-thumbnail.jpg'),
    name: 'Custom cakes',
    link: '/gallery/custom-cakes'
  },
  {
    image: require('./../../images/gallery/Dessert-table/gallery-thumbnail.jpg'),
    name: 'Dessert table',
    link: '/gallery/dessert-table'
  },
  {
    image: require('./../../images/gallery/Hamper/gallery-thumbnail.jpg'),
    name: 'Hampers',
    link: '/gallery/hampers'
  },
  {
    image: require('./../../images/gallery/Regular-cake/gallery-thumbnail.jpg'),
    name: 'Regular cake',
    link: '/gallery/regular-cakes'
  },
  {
    image: require('./../../images/gallery/Cakesickle-cake-pop/gallery-thumbnail.jpg'),
    name: 'Cakesickle & cake pop',
    link: '/gallery/cakesickles-and-cake-pops'
  },
  {
    image: require('./../../images/gallery/wedding-cake/gallery-thumbnail.jpg'),
    name: 'Wedding cake',
    link: '/gallery/wedding-cakes'
  },
  {
    image: require('./../../images/gallery/Doll-cake/gallery-thumbnail.jpg'),
    name: 'Doll cake',
    link: '/gallery/doll-cakes'
  },
  {
    image: require('./../../images/gallery/Photo-print/gallery-thumbnail.jpg'),
    name: 'Photo print cakes',
    link: '/gallery/photo-print-cakes'
  },
]

class Gallery extends Component {

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + img1 + ")" }}>
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Gallery</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to={'/'}><i className="fa fa-home"></i></Link></li>
                    <li>Gallery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full content-inner-1 bg-white" style={{ backgroundImage: "url(" + img2 + ")" }}>
              <div className="container">
                <div className="row justify-content-center">
                  {galleryDeatils.map((item, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-6 my-lg-3">
                      <Link to={item.link}>
                        <div className="port-box1 text-white" key={index}>
                          <div className="dlab-media">
                            <img src={item.image} alt={item.name} />
                          </div>
                          <div className="dlab-info">
                            <h2 className="title">{item.name}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Gallery;