
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

var bgfoter = require('./../../images/background/footer-img.jpg');

class Footer extends Component {
  render() {
    return (
      <footer className="site-footer " style={{ backgroundImage: "url(" + bgfoter + ")", backgroundSize: 'cover', backgroundPosition: 'center' }} >
        <div className="footer-top bg-line-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title text-white">Contact Us</h5>
                  <ul>
                    <li>
                      <i className="fa fa-mobile"></i>
                      <p><a className='text-white' href="tel:7447825859">(+91) 7447825859</a></p>
                    </li>
                    <li>
                      <i className="fa fa-envelope"></i>
                      <p><a className='text-white' href="mailto:cakeparadise010@gmail.com">cakeparadise010@gmail.com</a></p>
                    </li>
                    
                    <li>
                      <i className="fa fa-map-marker"></i>
                      <p>H.no. 153/8, Durgabhat, Ponda - Goa</p>
                    </li>
                    <li className='pl-0'>
                      <ul className="dlab-social-icon dlab-social-icon-lg">
                        <li><a href='https://www.facebook.com/CakeParadiseGoa?mibextid=ZbWKwL' className="fa fa-facebook bg-primary mr-1" rel="noreferrer" target="_blank"></a></li>
                        <li><a href='https://g.co/kgs/Xwp9SYS' className="fa fa-google bg-primary mr-1" rel="noreferrer" target="_blank"></a></li>
                        <li><a href='https://www.instagram.com/cakeparadise_goa/' className="fa fa-instagram bg-primary mr-1" rel="noreferrer" target="_blank"></a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="widget widget_services border-0">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul className="list-2">
                    <li><Link to={'/'}>Home</Link></li>
                    <li><Link to={'/about-us'}>About us</Link></li>
                    <li><Link to={'/gallery'}>Gallery</Link></li>
                    <li><Link to={'/contact-us'}>Contact Us</Link></li>
                    <li><Link to={'/menu'}>Our Menu</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center"> <span>Copyright © 2024 Cake Paradise. all rights reserved.</span> </div>
            </div>
          </div>
        </div>
        <div className='our-menu-float'>
          <Link to={'/menu'} className="menu-logo text-black"><img src={require('./../../images/icons/menu.png')} alt="Our menu" /> Our Menu</Link>
        </div>
      </footer>
    )
  }
}

export default Footer;
