import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

var bgimg1 = require('./../../images/banner/bnr1.jpg');
var bgimg2 = require('./../../images/background/bg5.jpg');

class Contact extends Component {

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bgimg1 + ")" }}>
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Contact</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
                    <li>Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full content-inner-2 contact-form bg-white" style={{ backgroundImage: "url(" + bgimg2 + ")", backgroundSize: "100%" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 d-flex m-md-b30 m-lg-b30">
                    <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm bg-white">
                      <h3 className="m-b5">Quick Contact</h3>
                      <p>If you have any questions simply use the following contact details.</p>
                      <ul className="no-margin">
                        <li className="icon-bx-wraper left m-b30">
                        <div className="icon-bx-xs border-1 text-primary"><Link to={''} className="icon-cell"><i className="ti-mobile"></i></Link> </div>
                          <div className="icon-content">
                            <h6 className="text-uppercase m-tb0 dlab-tilte">PHONE</h6>
                            <p><a href="tel:7447825859">(+91) 7447825859</a></p>
                          </div>
                        </li>
                        <li className="icon-bx-wraper left  m-b30">
                          <div className="icon-bx-xs border-1 text-primary"> <Link to={''} className="icon-cell"><i className="ti-email"></i></Link> </div>
                          <div className="icon-content">
                            <h6 className="text-uppercase m-tb0 dlab-tilte">Email:</h6>
                            <p><a href='mailto:cakeparadise010@gmail.com'>cakeparadise010@gmail.com</a></p>
                          </div>
                        </li>
                        <li className="icon-bx-wraper left">
                          
                        <div className="icon-bx-xs border-1 text-primary"> <Link to={''} className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
                          <div className="icon-content">
                            <h6 className="text-uppercase m-tb0 dlab-tilte">Address:</h6>
                            <p>H.No. 153/8, Durgabhat, Ponda - Goa</p>
                          </div>
                        </li>
                      </ul>
                      <div className="m-t20">
                        <ul className="dlab-social-icon dlab-social-icon-lg">
                          <li><a href='https://www.facebook.com/CakeParadiseGoa?mibextid=ZbWKwL' className="fa fa-facebook bg-primary mr-1" rel="noreferrer" target="_blank"></a></li>
                          <li><a href='https://g.co/kgs/Xwp9SYS' className="fa fa-google bg-primary mr-1" rel="noreferrer" target="_blank"></a></li>
                          <li><a href='https://www.instagram.com/cakeparadise_goa/' className="fa fa-instagram bg-primary mr-1" rel="noreferrer" target="_blank"></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 d-flex contact-iframe">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.574012898897!2d74.01029657512194!3d15.399539985187108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfbb0d296a8fa5%3A0x7d93d74b66f77eae!2sCake%20Paradise!5e0!3m2!1sen!2sin!4v1710699033326!5m2!1sen!2sin"
                      loading="lazy" title='Address'></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Contact;