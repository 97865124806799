import React from 'react';
import { useState } from "react";
import { Link } from 'react-router-dom';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import Lightbox from "react-image-lightbox";
import { Gallery } from "react-grid-gallery";
import "react-image-lightbox/style.css";

var img1 = require('./../../../images/banner/bnr1.jpg');

const images = [
  {
    src: require('./../../../images/gallery/Custom-cakes/pic1.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic1.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic2.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic2.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic3.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic3.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic4.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic4.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic5.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic5.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic6.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic6.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic7.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic7.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic8.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic8.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic9.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic9.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic10.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic10.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic11.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic11.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic12.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic12.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic13.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic13.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic14.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic14.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic15.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic15.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic16.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic16.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic17.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic17.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic18.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic18.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic19.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic19.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic20.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic20.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic21.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic21.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic22.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic22.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic23.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic23.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic24.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic24.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic25.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic25.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic26.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic26.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic27.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic27.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic28.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic28.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic29.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic29.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic30.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic30.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic31.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic31.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic32.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic32.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic33.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic33.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic34.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic34.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic35.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic35.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic36.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic36.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic37.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic37.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic38.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic38.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic39.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic39.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic40.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic40.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic41.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic41.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic42.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic42.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic43.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic43.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic44.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic44.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic45.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic45.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic46.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic46.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic47.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic47.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic48.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic48.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic49.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic49.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/pic50.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/pic50.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
  {
    src: require('./../../../images/gallery/Custom-cakes/gallery-thumbnail.jpg'),
    original: require('./../../../images/gallery/Custom-cakes/gallery-thumbnail.jpg'),
    tags: [
      { value: "", title: "Custom Cakes" },
    ],
    caption: "Custom Cakes",
  },
];

export default function CustomCakes() {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <>
      <Header />
      <div className="dlab-bnr-inr overlay-black-middle mb-5" style={{ backgroundImage: "url(" + img1 + ")" }}>
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Custom Cakes</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li><Link to={'/'}><i className="fa fa-home"></i></Link></li>
                <li>Custom Cakes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          height={images.height}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      <div className='pb-5'></div>
      <Footer />
    </>
  );
}