import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
  render() {
    return (
      <div className="dlab-accordion faq-style1 text-center">
        <img className='ownerr' src={require('./../../images/owner.jpg')} alt="" />
      </div>
    )
  }

}

export default About;