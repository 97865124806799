import React from 'react';
import { useState } from "react";
import { Link } from 'react-router-dom';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import Lightbox from "react-image-lightbox";
import { Gallery } from "react-grid-gallery";
import "react-image-lightbox/style.css";

var img1 = require('./../../../images/banner/bnr1.jpg');

const images = [
  {
    src: require('./../../../images/gallery/Doll-cake/doll-cake-1.jpg'),
    original: require('./../../../images/gallery/Doll-cake/doll-cake-1.jpg'),
    tags: [
      { value: "", title: "Doll Cake" },
    ],
    caption: "Doll Cake",
  },
  {
    src: require('./../../../images/gallery/Doll-cake/gallery-thumbnail.jpg'),
    original: require('./../../../images/gallery/Doll-cake/gallery-thumbnail.jpg'),
    tags: [
      { value: "", title: "Doll Cake" },
    ],
    caption: "Doll Cake",
  },
];

export default function DollCake() {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <>
      <Header />
      <div className="dlab-bnr-inr overlay-black-middle mb-5" style={{ backgroundImage: "url(" + img1 + ")" }}>
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Cupcakes</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li><Link to={'/'}><i className="fa fa-home"></i></Link></li>
                <li>Cupcakes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          height={images.height}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      <div className='pb-5'></div>
      <Footer />
    </>
  );
}