import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Cakeitem from '../Element/Menuitem';

var img1 = require('./../../images/banner/bnr1.jpg');
var img2 = require('./../../images/background/bg5.jpg');

class Ourmenustyle extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + img1 + ")", backgroundPosition: "center" }}>
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Menu</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
                    <li>Menu</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + img2 + ")", backgroundSize: "100%" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center">
                      <div className="icon-bx icon-bx-xl">
                        <img className='baker-img' src={require('./../../images/CAKE 12.png')} alt="cake paradise baker" />
                      </div>
                      <h3>Cakes</h3>
                    </div>
                  </div>
                </div>
                <Cakeitem />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Ourmenustyle;