import React, { Component } from 'react';



class Cakeitem extends Component {
  render() {
    return (
      <>
        {/* CAKES MENU */}
        <div className="row">
          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Vanilla
                    </span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Strawberry Delite</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Pineapple Delite</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>White Forest</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Butterscotch</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Rasmalai</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul className="menu-list">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Black Forest</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Chocolate Strawberry</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Oreo</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Choco Truffle</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Choco Mousse</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Chocolate Coffee </span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12">
            <p className='mb-0 pl-2'><b>Note:</b></p>
            <ul className='pl-4'>
              <li>Minimum order quantity is half kg</li>
              <li>If you are looking any other flavour which isn't mentioned in the menu, than please reach
                out to us.</li>
              <li> price varies depending on flavour and customisation</li>
            </ul>
          </div>
        </div>

        <hr />
        {/* CUPCAKES MENU */}
        <div className="row">
          <div className="col-12 section-head text-center pt-3 pt-lg-5">
            <h3>Cupcakes</h3>
          </div>
          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Vanilla</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Strawberry Delite</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Blueberry Delite</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Chocolate Strawberry</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>


          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Oreo</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Choco Truffle</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Choco Mousse</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Chocolate Coffee</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12">
            <p className='mb-0 pl-2'><b>Note:</b></p>
            <ul className='pl-4'>
              <li>Minimum order quantity is 6 pcs</li>
              <li>price varies depending on flavour and customisation</li>
            </ul>
          </div>
        </div>

        <hr />
        {/* Chocolate box MENU */}
        <div className="row">
          <div className="col-12 section-head text-center pt-3 pt-lg-5">
            <h3>Chocolate Box</h3>
          </div>
          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Dark Chocolate</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Milk Chocolate</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>   White Chocolate</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Strawberry</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Mango</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Dual Chocolate</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Butterscotch</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Pistachio</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Chocolate Truffle With Almond</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Rasmalai</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12">
            <p className='mb-0 pl-2'><b>Note:</b></p>
            <ul className='pl-4'>
              <li>Available in the box of 6,9,12</li>
              <li>Customised box packing for bulk orders are available</li>
            </ul>
          </div>
        </div>

        <hr />
        {/* Chocolate bar MENU */}
        <div className="row">
          <div className="col-12 section-head text-center pt-3 pt-lg-5">
            <h3>Chocolate bar</h3>
          </div>
          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>  Rose Chocolate</span>
                  </h4>
                </div>
              </li>

              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Strawberry Chocolate</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Mango chocolate</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>   Butterscotch</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Coffee crackle</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Pistachio</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i>Rasmalai</span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="menu-item">
                  <h4 className="title">
                    <span><i className="la la-check-circle-o"></i> Oreo</span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12">
            <p className='mb-0 pl-2'><b>Note:</b></p>
            <ul className='pl-4'>
              <li>Customised box packing for bulk orders are available</li>
            </ul>
          </div>
        </div>
      </>

    )

  }
}




export default Cakeitem;
