import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index from './Pages/Index';
import About from './Pages/About';
import Error from './Pages/Error';
import Gallery from './Pages/Gallery';
import Chocolate from './Pages/Gallery/Chocolate';
import CakesicklesAndCakePops from './Pages/Gallery/CakesicklesAndCakePops';
import Cupcakes from './Pages/Gallery/Cupcakes';
import DessertTable from './Pages/Gallery/DessertTable';
import CustomCakes from './Pages/Gallery/CustomCakes';
import DollCake from './Pages/Gallery/DollCake';
import Hamper from './Pages/Gallery/Hamper';
import PhotoPrint from './Pages/Gallery/PhotoPrint';
import RegularCake from './Pages/Gallery/RegularCake';
import Tier2 from './Pages/Gallery/Tier2';
import WeddingCake from './Pages/Gallery/WeddingCake';
import Ourmenustyle from './Pages/Ourmenustyle';
import Contact from './Pages/Contact';
// import Faq from './Pages/Faq';

class Markup extends Component {
  render() {
    return (
      <BrowserRouter basename='/'>
        <Switch>
          <Route path='/' exact component={Index} />

          <Route path='/about-us' exact component={About} />
          <Route path='/contact-us' exact component={Contact} />
          <Route path='/menu' exact component={Ourmenustyle} />
          <Route path='/404' exact component={Error} />
          <Route path='/gallery' exact component={Gallery} />

          <Route path='/gallery/chocolates' exact component={Chocolate} />
          <Route path='/gallery/cakesickles-and-cake-pops' exact component={CakesicklesAndCakePops} />
          <Route path='/gallery/cupcakes' exact component={Cupcakes} />
          <Route path='/gallery/2-tier-cakes' exact component={Tier2} />
          <Route path='/gallery/dessert-table' exact component={DessertTable} />
          <Route path='/gallery/doll-cakes' exact component={DollCake} />
          <Route path='/gallery/hampers' exact component={Hamper} />
          <Route path='/gallery/photo-print-cakes' exact component={PhotoPrint} />
          <Route path='/gallery/wedding-cakes' exact component={WeddingCake} />
          <Route path='/gallery/regular-cakes' exact component={RegularCake} />
          <Route path='/gallery/custom-cakes' exact component={CustomCakes} />

          {/* <Route path='/faq' exact component={Faq} /> */}

          <Route component={Error} />
        </Switch>
      </ BrowserRouter>
    )
  }
}

export default Markup;