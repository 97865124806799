import React, { Component } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { Link } from 'react-router-dom';
import Slider from '../Element/Slider';
import About from '../Element/About';
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Images	
var img1 = require('./../../images/background/bg5.jpg');
var weddingCake = require('./../../images/our-services/wedding-cake.jpg');
var cupcakes = require('./../../images/our-services/cupcake.jpg');
var desertTable = require('./../../images/our-services/desert-table.jpg');
var cake = require('./../../images/our-services/cakes.jpg');
var img2 = require('./../../images/background/bg1.jpg');
var img3 = require('./../../images/background/bg5.jpg');
var img4 = require('./../../images/background/bg4.jpg');

const galleryDeatils = [
  {
    image: require('./../../images/gallery/tier2/gallery-thumbnail.png'),
    name: '2 Tier Cakes',
    link: '/gallery/2-tier-cakes'
  },
  {
    image: require('./../../images/gallery/Chocolate/gallery-thumbnail.jpg'),
    name: 'Chocolates',
    link: '/gallery/chocolates'
  },
  {
    image: require('./../../images/gallery/Cupcakes/gallery-thumbnail.jpg'),
    name: 'Cupcakes',
    link: '/gallery/cupcakes'
  },
  {
    image: require('./../../images/gallery/Custom-cakes/gallery-thumbnail.jpg'),
    name: 'Custom cakes',
    link: '/gallery/custom-cakes'
  },
]

class Index extends Component {
  render() {
    return (
      <>
        {/* <HelmetProvider>
          <Helmet>
            <title>Cake paradise - Customised cake solutions tailored to your needs</title>
            <meta content="We provide customised cake solutions tailored to your needs. Choose from our wide range of flavours and designs or let us know what you have in mind - we will make it happen!" name="description" />
            <meta property="og:title" content="Cake Paradise" />
            <meta property="og:description" content="We provide customised cake solutions tailored to your needs. Choose from our wide range of flavours and designs or let us know what you have in mind - we will make it happen!" />
            <meta property="og:url" content="https://cakeparadise.in/" />
            <meta property="og:site_name" content="Cake Paradise" />
            <meta property="og:image" content="https://cakeparadise.in/logo192.jpg" />
          </Helmet>
        </HelmetProvider> */}

        <Header />
        <div className="page-content bg-white">
          <div className="content-block">
            <Slider />
            <div className="section-full content-inner-3" style={{ backgroundImage: "url(" + img1 + ")", backgroundSize: "100%" }}>
              <div className="container">
                <div className="row service-area1">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + cake + ")" }}>
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">2 Tier Cakes</h2>
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={('/gallery/2-tier-cakes')} className="btn btnhover">View All <i className="fa fa-angle-double-right m-l5"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + weddingCake + ")" }}>
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">Wedding Cakes</h2>
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={('/gallery/wedding-cakes#')} className="btn btnhover">View All <i className="fa fa-angle-double-right m-l5"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + desertTable + ")" }}>
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">Dessert Table</h2>
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={('/gallery/dessert-table#')} className="btn btnhover">View All <i className="fa fa-angle-double-right m-l5"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + cupcakes + ")" }}>
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">Cupcakes</h2>
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={('/gallery/cupcakes#')} className="btn btnhover">View All <i className="fa fa-angle-double-right m-l5"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head mb-0 text-center">
                      <div className="icon-bx icon-bx-xl">
                        <img className='baker-img' src={require('./../../images/CAKE 12.png')} alt="cake paradise baker" />
                      </div>
                      <h3 className="">Whether it’s a birthday party or an anniversary celebration</h3>
                      <p>We provide customised cake solutions tailored to your needs. Choose from our wide range of flavours and designs or let us know what you have in mind – we will make it happen!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full content-inner service-area2 bg-img-fix bg-line-top bg-line-bottom" style={{ backgroundImage: "url(" + img4 + ")", backgroundSize: "cover", backgroundPosition: "bottom", boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 60%)" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center">
                      <h2 className="text-white">What Do We Offer For You?</h2>
                      <p className="text-bold">Scrumptious treats that will make your celebrations extra Special!</p>
                      <div className="dlab-separator style1 bg-primary"></div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-4 m-b30">
                    <img src={require('./../../images/about/pic1.jpg')} className="img-cover radius-sm" alt="" />
                  </div>
                  <div className="col-lg-8">
                    <div className="row p-l30">
                      <div className="col-lg-6 col-sm-6 m-b30">
                        <Link to={'/gallery/custom-cakes'} className="icon-cell">
                          <div className="icon-bx-wraper text-white service-box2">
                            <div className="icon-bx">
                              <img src={require('./../../images/icons/service-icon/icon1.png')} alt="Customised cakes" />
                            </div>
                            <div className="icon-content">
                              <h5 className="dlab-tilte">Customised cakes</h5>
                              {/* <p>Lorem Ipsum is dummy</p> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-sm-6 m-b30">
                        <Link to={''} className="icon-cell">
                          <div className="icon-bx-wraper text-white service-box2">
                            <div className="icon-bx">
                              <img src={require('./../../images/icons/service-icon/icon3.png')} alt="Cupcakes" />
                            </div>
                            <div className="icon-content">
                              <h5 className="dlab-tilte">Cupcakes</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-sm-6 m-b30">
                        <Link to={'/gallery/chocolates'} className="icon-cell">
                          <div className="icon-bx-wraper text-white service-box2">
                            <div className="icon-bx"><img src={require('./../../images/icons/service-icon/chocolate-bar.png')} alt="Chocolates" />
                            </div>
                            <div className="icon-content">
                              <h5 className="dlab-tilte">Chocolates</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-sm-6 m-b30">
                        <Link to={'/gallery/cakesickles-and-cake-pops'} className="icon-cell">
                          <div className="icon-bx-wraper text-white service-box2">
                            <div className="icon-bx"><img src={require('./../../images/icons/service-icon/cake-pop.png')} alt="Cake pops" />
                            </div>
                            <div className="icon-content">
                              <h5 className="dlab-tilte">Cake pops</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-sm-6 m-b30">
                        <Link to={'/gallery/hampers'} className="icon-cell">
                          <div className="icon-bx-wraper text-white service-box2">
                            <div className="icon-bx">
                              <img src={require('./../../images/icons/service-icon/hamper.png')} alt="Hamper" />
                            </div>
                            <div className="icon-content">
                              <h5 className="dlab-tilte">Hampers</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-sm-6 m-b30">
                        <Link to={'/gallery/cakesickles-and-cake-pops'} className="icon-cell">
                          <div className="icon-bx-wraper text-white service-box2">
                            <div className="icon-bx">
                              <img src={require('./../../images/icons/service-icon/Cakesickles.png')} alt="Cakesickles " />
                            </div>
                            <div className="icon-content">
                              <h5 className="dlab-tilte">Cakesickles</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full content-inner bg-gray" style={{ backgroundImage: "url(" + img2 + ")", backgroundSize: "100%" }}>
              <div className="container">
                <div className="row faq-area1">
                  <div className="col-lg-6 d-flex align-items-center">
                    <div className="m-r20">
                      <div className="section-head text-left">
                        <h2>Meet our founder, Siffa Shaik</h2>
                        <div className="dlab-separator style1 bg-primary"></div>
                      </div>
                      <div className="clearfix">
                        {/* <p>Siffa Shaik combined her love for baking and her creative mind in Ponda, Goa, to start her own baking business in the year 2019. She offers a wide range of delicious baked goods made from the freshest ingredients, all while maintaining her job as a digital marketer. With her distinct background, she brings a unique touch to her baking and offers a truly exceptional experience for her customers.</p> */}
                        <p>Siffa Shaik is a diligent baker hailing from Ponda, Goa. A Digital Marketer by profession and a Baker by heart, not letting her profession coming in between her passion for baking</p>
                        <p>She combined her love for baking and her creative mind to start her own baking business and offers a wide range of delicious baked goods made from the freshest ingredients, all while maintaining her job as a Digital Marketer. With her distinct background, she brings a unique touch to her baking and offers a truly exceptional experience for her customers.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <About />
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full bg-white">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 section-head">
                    <h3 className="text-center">Gallery</h3>
                  </div>
                  {/* Gallery loop */}
                  {galleryDeatils.map((item, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <Link to={item.link}>
                        <div className="port-box1 text-white" key={index}>
                          <div className="dlab-media">
                            <img src={item.image} alt={item.name} />
                          </div>
                          <div className="dlab-info">
                            <h2 className="title">{item.name}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/* Gallery loop ends */}
                  <div className="col-12">
                    <p className="text-center">
                      <Link to={('/gallery')} className="btn btn-md btnhover shadow m-t30">View All <i className="fa fa-angle-double-right m-l5"></i></Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full bg-white" style={{ backgroundImage: "url(" + img3 + ")", backgroundSize: "100%" }}>
              <div className="container content-inner py-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center">
                      <div className="icon-bx">
                        <img className='baker-img' src={require('./../../images/CAKE 12.png')} alt="cake paradise baker" />
                      </div>
                      <h3>Testimonials</h3>
                      <p>What our clients say about us</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25385781'
                      style={{ width: "100%", minHeight: "380px", border: 'none' }} title='Testimonials'></iframe>

                  </div>
                </div>
              </div>
              <div className="container">
                <p className="text-center"><a href='https://wa.me/7447825859' className="btn btn-md btnhover shadow m-t30" rel="noreferrer" target='_blank'>Treat yourself to freshly baked delights</a></p>
                <div className='address-iframe'>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.574012898897!2d74.01029657512194!3d15.399539985187108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfbb0d296a8fa5%3A0x7d93d74b66f77eae!2sCake%20Paradise!5e0!3m2!1sen!2sin!4v1710699033326!5m2!1sen!2sin"
                    loading="lazy" title='Address'></iframe>
                </div>
              </div>
              <div className="container content-inner">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center mb-0">
                      <div className="icon-bx">
                        <img className='baker-img' src={require('./../../images/CAKE 12.png')} alt="cake paradise baker" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Index;