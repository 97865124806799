import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

var img1 = require('./../../images/banner/bnr1.jpg');
var img2 = require('./../../images/background/bg5.jpg');

class About extends Component {

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + img1 + ")" }}>
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">About Us</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to={'/'}><i className="fa fa-home"></i></Link></li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + img2 + ")", backgroundSize: "100%" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center">
                      <div className="icon-bx icon-bx-xl">
                        <img className='baker-img' src={require('./../../images/CAKE 12.png')} alt="cake paradise baker" />
                      </div>
                      <h3>About Cake Paradise</h3>
                      <p className='mb-3'>Welcome to Cake Paradise, where passion meets creativity in every bite -Deliciously! </p>
                      <p className='mb-3'>Our home bakery is more than just a place for baked goods, it's a journey of flavors crafted with love to make your occasions a cherished memory.</p>
                      <p className='mb-3'>Founded in 2019 by Siffa Shaik in Ponda, Goa, We at Cake Paradise believe that every bite should be a moment of joy and to make that joy more exciting, we make sure to use only the freshest and highest-quality ingredients in our every recipe. From indulgent cakes to delicate pastries, each creation is crafted with care and precision, ensuring a taste experience that delights the senses and leaves a lasting impression resonating with our motto of customer satisfaction.</p>
                      <p className='mb-3'>We understand that every order is a celebration, whether it's a birthday cake, a wedding cake, a dessert table, or simply a sweet treat to brighten someone's day which is why we strive to exceed expectations with every creation, ensuring that each customer receives not just a product, but an unforgettable experience.</p>
                      <p className='mb-3'>We are your one stop solution for delectable desserts, which is not just a mixture of ingredients but a reflection of emotions you want to convey with every treat!</p>
                      {/* 
                      <p>It all started in the 2018 when the passion for baking started flaming within the founder of Cake Paradise and she began to learn the science behind baking. And ever since then there is no looking back.</p>
                      <p>From making the cake for her best friend’s father’s birthday to setting up stall in college fest to taking small orders after college hours. </p> */}
                      <div className='row pt-lg-5 pt-3'>
                        <div className='col-lg-6 text-center text-lg-left'>
                          <h3>Meet our founder</h3>
                          {/* <p>Siffa Shaik is a diligent baker hailing from Ponda Goa. A digital marketer by profession and a baker by heart, not letting her profession coming in between her passion for baking</p>
                          <p>Siffa Shaik combined her love for baking and her creative mind in Ponda, Goa, to start her own baking business in the year 2019. She offers a wide range of delicious baked goods made from the freshest ingredients, all while maintaining her job as a digital marketer. With her distinct background, she brings a unique touch to her baking and offers a truly exceptional experience for her customers.</p>
                          <p>With her unique background, she brings a unique touch to her baking and offers a truly exceptional experience for her customers.</p> */}
                          <p>Siffa Shaik is a diligent baker hailing from Ponda, Goa. A Digital Marketer by profession and a Baker by heart, not letting her profession coming in between her passion for baking</p>
                          <p>She combined her love for baking and her creative mind to start her own baking business and offers a wide range of delicious baked goods made from the freshest ingredients, all while maintaining her job as a Digital Marketer. With her distinct background, she brings a unique touch to her baking and offers a truly exceptional experience for her customers.</p>

                        </div>
                        <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                          <img className='ownerr' src={require('./../../images/owner.jpg')} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default About;