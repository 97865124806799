import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import slider1 from './../../images/main-slider/slide-1.jpg';
import slider2 from './../../images/main-slider/slide-2.jpg';

class Slider extends Component {
  render() {
    return (
      <div className="main-slider">
        <Carousel indicators={false}>
          <Carousel.Item>
            <div className="slide" style={{ backgroundImage: "url(" + slider1 + ")" }} >
              {/* <img className="d-block w-100 slider" src={require('./../../images/main-slider/slide1.jpg')}	alt="Second slide"	/> */}
              <div className="content">
                <h2 className="title">Delight in every bite </h2>
                <h4 className="sub-title">The Best Homemade Cakes in Goa</h4>
                <Link to={"/about-us"} className="btn btnhover">About Us</Link>
                <Link to={'/menu'} className="btn btnhover">Menu</Link>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slide" style={{ backgroundImage: "url(" + slider2 + ")" }} >
              {/* <img className="d-block w-100 slider"	src={require('./../../images/main-slider/slide2.jpg')}	alt="Second slide"	/> */}
              <div className="content">
                <h2 className="title">Making Every Celebration Sweeter </h2>
                <h4 className="sub-title">since 2019!</h4>
                <Link to={"/about-us"} className="btn btnhover">About Us</Link>
                <Link to={'/menu'} className="btn btnhover">Menu</Link>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    )
  }
}

export default Slider;